<template>
    <b-tab title="Travel Policy" lazy>
        <b-row class="mb-3">
            <b-col class="text-right">
                <b-button-group size="sm">
                    <b-button variant="success" @click="saveChanges">Save Changes</b-button>
                    <b-button size="sm" variant="danger" @click="revertChanges">Reload Policy</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <rich-text-editor v-model="policy"/>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {Vue, Component, Watch} from 'vue-property-decorator';
import RichTextEditor from '@/components/shared/RichTextEditor';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        RichTextEditor
    }
})
export default class EditTravelPolicy extends Vue {

    get policy() {
        return this.$store.getters['travel/policy'];
    }

    set policy(policy) {
        this.$store.commit('travel/setPolicy', policy);
    }

    async saveChanges() {
        try {
            await this.$store.dispatch('travel/savePolicy');
            this.$bvToast.toast('The Travel Policy has been saved!', {
                title: 'Saved',
                variant: 'success',
                solid: true
            });
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async revertChanges() {
        try {
            await this.$store.dispatch('travel/loadPolicy');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async mounted() {
        await this.revertChanges();
    }
}
</script>
<style scoped>

</style>