<template>
    <b-card no-body>
        <b-tabs small card>
            <edit-travel-policy/>
        </b-tabs>
    </b-card>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';
import EditTravelPolicy from '@/views/private/preferences/EditTravelPolicy';
import store from '@/store/store';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';

@Component({
    components: {
        EditTravelPolicy
    }
})
export default class Preferences extends Vue {

    beforeRouteEnter(to, from, next) {
        store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create('Preferences', null, true)
        ]);

        next();
    }

}
</script>
<style scoped>

</style>